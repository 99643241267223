import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    DatePicker,
    ColumnLayout,
    Container,
    FormField,
    Input, Link, Popover, ProgressBar, Select,
    SpaceBetween, Spinner,
    StatusIndicator, Textarea
} from "@amzn/awsui-components-react";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import {getDownloadURLForS3Object} from "src/api/getDownloadURLForS3Object";
import {updateCase} from "src/api/updateCase";
import {ColoredLine, convertENtoBRNotation, ValueWithLabel} from "src/utils/common-utils";
import {caseID} from "src/constants/common";
import {
    PENDING_PARSING_DISABLED_BUTTON_LIST,
    PENDING_TAX_CLASSIFICATION_DISABLED_BUTTON_LIST,
    STRYFE_USE_CASE_NAME, GL_STRING_PATTERN
} from "src/components/StryfeAPI/Constants";
import "./StryfeDashboard.scss"
import {SelectProps} from "@amzn/awsui-components-react/polaris/select";


function InvoiceInformation(props) {
    const [invoice, setInvoice]: any = useState(props.invoiceDetail);
    const [invoiceAmount, setInvoiceAmount]: any = useState(convertENtoBRNotation(invoice.invoiceAmount));
    const [caseStatus]: any = useState(props.caseStatus);
    const [updatedAttribute,setUpdateAttribute]:any = useState();
    const GRID_SPAN = 4;
    const caseId = STRYFE_USE_CASE_NAME+"#"+props.invoiceDetail[caseID];
    const [currentDownloadURL,setCurrentDownloadURL]:any = useState("");
    const [loadingPDF,setLoadingPDF] = useState(false);
    const [loadingMetadata, setLoadingMetadata] = useState(false);
    const [selectedAddresseeOption, setSelectedAddresseeOption] = useState<SelectProps.Option>({label: props.invoiceDetail.addressee, value: ''});
    const [selectedP2pOption, setSelectedP2pOption] = useState<SelectProps.Option>({label: props.invoiceDetail.p2pIssue, value: ''});
    const [selectedInvoiceOption, setSelectedInvoiceOption] = useState<SelectProps.Option>({label: props.invoiceDetail.invoiceType, value: ''});
    const [selectedFincomInvoiceOption, setSelectedFincomInvoiceOption] = useState<SelectProps.Option>({label: props.invoiceDetail.fincomStatus, value: ''});
    const [glStringValid, setGlStringValid] = useState(true);
    const handleGLStringChange = (event) => {
      const inputValue = event.detail.value;
        if (GL_STRING_PATTERN.test(inputValue)) {
            setGlStringValid(true);
      setInvoice((prevState) => ({
          ...prevState,
          glString: inputValue,
        }));
        setUpdateAttribute((prevState) => ({
          ...prevState,
          glString: inputValue,
        }));
      } else {
          setGlStringValid(false);
          setInvoice((prevState) => ({
                    ...prevState,
                    glString: inputValue,
                  }));
                  setUpdateAttribute((prevState) => ({
                    ...prevState,
                    glString: inputValue,
                  }));
          }
    };

    async function callUpdate(){
        setLoadingMetadata(true);
        setUpdateAttribute(prevState => ({
            ...prevState,
            taskToken: props.invoiceDetail.taskToken
        }));
        const updatedAttributeWithCustomFields = {
            ...updatedAttribute,
            finOpsFlag: false
        };
        let taskToken = props.invoiceDetail.taskToken;
        updateCase(updatedAttributeWithCustomFields,caseId, taskToken).then(resp => {
            setLoadingMetadata(false);
            window.location.reload();
        })
    }

    async function callUpdateOtherDetails(){
        setLoadingMetadata(true);
        if (!glStringValid) {
            alert("Not a valid GL string");
            setLoadingMetadata(false);
            return;
            }
        setUpdateAttribute(prevState => ({
            ...prevState,
            taskToken: props.invoiceDetail.taskToken
        }));
        const updatedAttributeWithCustomFields = {
            ...updatedAttribute,
            finOpsFlag: true
        };

        let taskToken = props.invoiceDetail.taskToken;
        updateCase(updatedAttributeWithCustomFields,caseId, taskToken).then(resp => {
            setLoadingMetadata(false);
            window.location.reload();
        })
    }

    async function fetchURL() {
        setLoadingPDF(true);
        const downloadUrl = await getDownloadURLForS3Object(props.invoiceDetail.barcode);
        setCurrentDownloadURL(downloadUrl);
        setLoadingPDF(false);
    }
    useEffect(() => {
        setInvoice(props.invoiceDetail);
        setInvoiceAmount(convertENtoBRNotation(props.invoiceDetail.invoiceAmount));
        setSelectedAddresseeOption({label: props.invoiceDetail.addressee, value: ''});
        setSelectedP2pOption({label: props.invoiceDetail.p2pIssue, value: ''});
        setSelectedInvoiceOption({label: props.invoiceDetail.invoiceType, value: ''});
        fetchURL();
    }, [props]);
    return (
            <div>
            <Container header={<h2>Invoice Information</h2>}>
                <div className="flex-container-two">
                <div style={{
                    width:'45vw'
                }}>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label={<div>Invoice Number <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.invoiceNumber} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    invoiceNumber: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    invoiceNumber: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label={<div>Invoice Amount <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoiceAmount} onChange={event => {
                               {
                                setInvoiceAmount(event.detail.value);
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    invoiceAmount: event.detail.value.replace(",",".").replace(".", "")
                                }));
                            }}}/>
                        </FormField>
                        <FormField label={<div>Supplier's Tax Id <span className="mandatory-marker">*</span></div>} >
                            <Input value={invoice.supplierTaxId} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    supplierTaxId: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    supplierTaxId: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label={<div>Supplier Name <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.supplierName} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    supplierName: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    supplierName: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label={<div>Amazon Tax Id <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.amazonTaxId} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    amazonTaxId: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    amazonTaxId: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label={<div>Service Code <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.serviceCode} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    serviceCode: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    serviceCode: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>

                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label={<div>Invoice ISS Rate <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.originalIssRate} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    originalIssRate: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    originalIssRate: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label={<div>Invoice INSS Rate <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.originalInssRate} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    originalInssRate: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    originalInssRate: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label={<div>Invoice IRRF Rate <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.originalIrrfRate} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    originalIrrfRate: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    originalIrrfRate: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>


                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label={<div>Amazon Location <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.orgName} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    orgName: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    orgName: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label={<div>LC 116 Service Code <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.lc116ServiceCode} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    lc116ServiceCode: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    lc116ServiceCode: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label={<div>Invoice Model <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.fiscalDocumentModel} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    fiscalDocumentModel: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    fiscalDocumentModel: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label={<div>Invoice Date <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.invoiceDate} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    invoiceDate: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    invoiceDate: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label={<div>PO Number or GL String <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.poNumber} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    poNumber: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    poNumber: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Invoice Series">
                            <Input value={invoice.invoiceSeries} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    invoiceSeries: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    invoiceSeries: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="Supplier City">
                            <Input value={invoice.supplierCity} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    supplierCity: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    supplierCity: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Amazon Name">
                            <Input value={invoice.amazonName} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    amazonName: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    amazonName: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Validation Code">
                            <Input value={invoice.validationCode} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    validationCode: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    validationCode: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="Invoice Currency">
                            <Input value={invoice.invoiceCurrency} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    invoiceCurrency: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    invoiceCurrency: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="GL Requester">
                            <Input value={invoice.glRequester} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    glRequester: event.detail.value
                                }));
                                setInvoice(prevState => ({
                                    ...prevState,
                                    glNumber: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Addressee">
                            <Select
                                selectedOption={selectedAddresseeOption}
                                options={[
                                    { label: "TAX", value: "1" },
                                    { label: "AP", value: "2" },
                                    { label: "Importation of Services", value: "3" },
                                    { label: "Other", value: "4" },
                                ]}
                                onChange={event => {
                                    setSelectedAddresseeOption(event.detail.selectedOption);
                                    setInvoice(prevState => ({
                                        ...prevState,
                                        addressee: event.detail.selectedOption.label
                                    }));
                                    setUpdateAttribute(prevState => ({
                                        ...prevState,
                                        addressee: event.detail.selectedOption.label
                                    }));
                                }}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="Invoice Type">
                            <Select
                                selectedOption={selectedInvoiceOption}
                                options={[
                                    { label: "Service", value: "1" },
                                    { label: "Debit Note", value: "2" },
                                    { label: "Goods", value: "3" },
                                    { label: "Transport", value: "4" },
                                    { label: "Devolution Note", value: "5" },
                                    { label: "Proof of Cancellation", value: "6" },
                                    { label: "Credit Note", value: "7" },
                                    { label: "Other", value: "8" },
                                ]}
                                onChange={event => {
                                    setSelectedInvoiceOption(event.detail.selectedOption);
                                    setInvoice(prevState => ({
                                        ...prevState,
                                        invoiceType: event.detail.selectedOption.label
                                    }));
                                    setUpdateAttribute(prevState => ({
                                        ...prevState,
                                        invoiceType: event.detail.selectedOption.label
                                    }));
                                }}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                    </Grid>

                    <Grid gridDefinition={[{colspan: 1.5}, {colspan: 1.5},]}>
                        <Button disabled = {PENDING_PARSING_DISABLED_BUTTON_LIST.includes(caseStatus)}
                                variant="primary" onClick={callUpdate} loading={loadingMetadata}>Save</Button>
                    </Grid>

                    <div>
                        <br/>
                        <hr></hr>
                        <br/>
                        <h4><b>Tax Classification Information</b></h4>
                    </div>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label={<div>SOP Number <span className="mandatory-marker">*</span></div>}>
                            <Input value={invoice.sopNumber} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    sopNumber: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    sopNumber: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Company Code">
                            <Input value={invoice.companyCode} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    companyCode: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    companyCode: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="OFA Location">
                            <Input value={invoice.ofaLocation} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    ofaLocation: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    ofaLocation: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="PIS/COFINS Credit">
                            <Input value={invoice.cofinsCredit} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    cofinsCredit: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    cofinsCredit: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Invoice Type">
                            <Input value={invoice.invoiceType} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    invoiceType: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    invoiceType: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="IRRF Withholding">
                            <Input value={invoice.irrfWithholdingAmount} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    irrfWithholdingAmount: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    irrfWithholdingAmount: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="PCC withholding amount">
                            <Input value={invoice.pccWithholdingAmount} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    pccWithholdingAmount: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    pccWithholdingAmount: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="INSS Withholding Amount">
                            <Input value={invoice.inssWithholdingAmount} disabled={PENDING_TAX_CLASSIFICATION_DISABLED_BUTTON_LIST.includes(caseStatus)} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    inssWithholdingAmount: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    inssWithholdingAmount: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="INSS Penalties">
                            <Input value={invoice.inssPenalties} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    inssPenalties: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    inssPenalties: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="ISS Calculation Basis">
                            <Input value={invoice.issCalculationBasis} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    issCalculationBasis: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    issCalculationBasis: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="ISS Withholding amount">
                            <Input value={invoice.issWithholdingAmount} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    issWithholdingAmount: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    issWithholdingAmount: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="ISS Penalties">
                            <Input value={invoice.issPenalties} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    issPenalties: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    issPenalties: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="CST PIS">
                            <Input value={invoice.cstPIS} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    cstPIS: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    cstPIS: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="CST COFINS">
                            <Input value={invoice.cstCOFINS} disabled={true} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    cstCOFINS: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    cstCOFINS: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>

                    <Grid gridDefinition={[{colspan: GRID_SPAN}]}>
                        <FormField label="System Comments">
                            <Textarea value = {invoice.systemComments} disabled={true}
                              placeholder="System comments will be visible here"/>
                        </FormField>
                    </Grid>

                    <Grid gridDefinition={[{colspan: 1.5}, {colspan: 1.5},]}>
                        <Button disabled = {PENDING_TAX_CLASSIFICATION_DISABLED_BUTTON_LIST.includes(caseStatus)}
                                variant="primary" onClick={callUpdate} loading={loadingMetadata}>Save</Button>
                    </Grid>

                    <div>
                        <br/>
                        <hr></hr>
                        <br/>
                        <h4><b>FinOps Invoice Management</b></h4>
                    </div>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="Status">
                            <Select
                                selectedOption={selectedFincomInvoiceOption}
                                options={[
                                    { label: "Pending", value: "1" },
                                    { label: "WIP Fincom", value: "2" },
                                    { label: "WIP IR", value: "3" },
                                    { label: "Completed", value: "4" },
                                    { label: "Rejected", value: "5" },
                                    { label: "Defect Logger", value: "6" },
                                    { label: "Defect Fixed", value: "7" },
                                    { label: "Volcano Duplicate", value: "8" },
                                    { label: "Fatura", value: "9" },
                                ]}
                                onChange={event => {
                                    setSelectedFincomInvoiceOption(event.detail.selectedOption);
                                    setInvoice(prevState => ({
                                        ...prevState,
                                        fincomStatus: event.detail.selectedOption.label
                                    }));
                                    setUpdateAttribute(prevState => ({
                                        ...prevState,
                                        fincomStatus: event.detail.selectedOption.label
                                    }));
                                }}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        <FormField label="PO Owner">
                            <Input value={invoice.poOwner} disabled={false} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    poOwner: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    poOwner: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Date Scanned">
                            <DatePicker
                                value={invoice.dateScanned}
                                onChange={(date) => {
                                    setInvoice(prevState => ({
                                        ...prevState,
                                        dateScanned: date.detail.value
                                    }));
                                    setUpdateAttribute(prevState => ({
                                        ...prevState,
                                        dateScanned: date.detail.value
                                    }));
                                }}
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="StartDate"
                            />
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="Owner Processing">
                            <Input value={invoice.ownerProcessing} disabled={false} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    ownerProcessing: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    ownerProcessing: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="OFA REC Number">
                            <Input value={invoice.ofaRECNumber} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    ofaRECNumber: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    ofaRECNumber: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Priority">
                            <Input value={invoice.priority} disabled={false} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    priority: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    priority: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="Ticket Number">
                            <Input value={invoice.ticketNumber} disabled={false} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    ticketNumber: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    ticketNumber: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="Last Comment History">
                            <Input value={invoice.lastComment} disabled={false} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    lastComment: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    lastComment: event.detail.value
                                }));
                            }}/>
                        </FormField>
                        <FormField label="OFA Invoice Type">
                            <Input value={invoice.ofaInvoiceType} disabled={false} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    ofaInvoiceType: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    ofaInvoiceType: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: GRID_SPAN}, {colspan: GRID_SPAN}, {colspan: GRID_SPAN}]}>
                        <FormField label="P2P issue">
                            <Select
                                selectedOption={selectedP2pOption}
                                options={[
                                    { label: "AMZ_INVALID_ACCOUNT", value: "1" },
                                    { label: "AMZ_PRICE_TOL", value: "2" },
                                    { label: "AMZ_QTD_FAT", value: "3" },
                                    { label: "GL ACCOUNT STRING", value: "4" },
                                    { label: "INCORRECT CLASIFICACION", value: "5" },
                                    { label: "INCORRECT CURRENCY", value: "6" },
                                    { label: "INCORRECT PO/GL FORMAT", value: "7" },
                                    { label: "INVALID PO MATCHING TYPE", value: "8" },
                                    { label: "MANUAL ENTRY", value: "9" },
                                    { label: "MISSING GL APPROVER", value: "10" },
                                    { label: "MISSING GL/PO", value: "11" },
                                    { label: "MISSING PROOF OF CANCELLATION", value: "12" },
                                    { label: "NO NEED A TT", value: "13" },
                                    { label: "OTHER", value: "14" },
                                    { label: "PO INTEGRATION ERROR", value: "15" },
                                    { label: "PO MATCH X VENDOR INFORMATION", value: "16" },
                                    { label: "PO_NOT_FOUND_TO_LOCATION", value: "17" },
                                    { label: "QTY CONSUMED IN IRM BY INTERNAL ADJUSMENT", value: "18" },
                                    { label: "STATUS PURCH INVALID", value: "19" },
                                    { label: "TAXOPS ACTION PENDING/CONTRIBUTOR TYPE", value: "20" },
                                    { label: "TAXOPS ACTION PENDING/ENABLE TAX FIELD", value: "21" },
                                    { label: "TAXOPS ACTION PENDING/MISSING SERVICE CODE", value: "22" },
                                    { label: "VENDOR NOT FOUND", value: "23" },
                                    { label: "TAX GUIDANCE", value: "24" }
                                ]}
                                onChange={event => {
                                    setSelectedP2pOption(event.detail.selectedOption);
                                    setInvoice(prevState => ({
                                        ...prevState,
                                        p2pIssue: event.detail.selectedOption.label
                                    }));
                                    setUpdateAttribute(prevState => ({
                                        ...prevState,
                                        p2pIssue: event.detail.selectedOption.label
                                    }));
                                }}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        <FormField label="GL String">
                            <Input id = "glInput"
                            value={invoice.glString}
                            disabled={false}
                            invalid={!glStringValid}
                            onChange={handleGLStringChange}
                                    />
                        </FormField>
                        <FormField label="FinCom Owner">
                            <Input value={invoice.finComOwner} disabled={false} onChange={event => {
                                setInvoice(prevState => ({
                                    ...prevState,
                                    finComOwner: event.detail.value
                                }));
                                setUpdateAttribute(prevState => ({
                                    ...prevState,
                                    finComOwner: event.detail.value
                                }));
                            }}/>
                        </FormField>
                    </Grid>
                    <Grid gridDefinition={[{colspan: 1.5}, {colspan: 1.5},]}>
                        <Button disabled = {false}
                                variant="primary" onClick={callUpdateOtherDetails} loading={loadingMetadata}>Save</Button>
                    </Grid>
                </div>
                    <div style={{
                        marginLeft:'60px'
                    }}>
                        <h2>PDF</h2>
                        {loadingPDF ? <Spinner size="large"/> : <iframe src={currentDownloadURL} className="iframeDimension">
                            </iframe>
                        }
                    </div>
                </div>
            </Container>
            <br/>
            {/*Commenting the below sections. To be added back once their data can be handled by Stryfe*/}
            {/*<Container header={<h2>Vendor's Validation</h2>}>*/}
            {/*    <ColumnLayout columns={3} variant="text-grid">*/}
            {/*        <SpaceBetween size="l">*/}
            {/*            <ValueWithLabel label="Validation Status">--*/}
            {/*            </ValueWithLabel>*/}
            {/*            <ValueWithLabel label="Reason for failure">--*/}
            {/*            </ValueWithLabel>*/}
            {/*        </SpaceBetween>*/}
            {/*    </ColumnLayout>*/}
            {/*</Container>*/}
            {/*<br/>*/}
            {/*<Container header={<h2>TT Information</h2>}>*/}
            {/*    <ColumnLayout columns={3} variant="text-grid">*/}
            {/*        <SpaceBetween size="l">*/}
            {/*            <ValueWithLabel label="Ticket No.">--*/}
            {/*            </ValueWithLabel>*/}
            {/*            <ValueWithLabel label="Assignee">--*/}
            {/*            </ValueWithLabel>*/}
            {/*        </SpaceBetween>*/}
            {/*        <SpaceBetween size="l">*/}
            {/*            <ValueWithLabel label="GL Account">{invoice.glAcount}</ValueWithLabel>*/}
            {/*            <ValueWithLabel label="Status">--*/}
            {/*            </ValueWithLabel>*/}
            {/*        </SpaceBetween>*/}
            {/*        <SpaceBetween size="l">*/}
            {/*            <ValueWithLabel label="Aging">--</ValueWithLabel>*/}
            {/*            <ValueWithLabel label="Penalties">--*/}
            {/*            </ValueWithLabel>*/}
            {/*        </SpaceBetween>*/}
            {/*    </ColumnLayout>*/}
            {/*</Container>*/}
            {/*<br/>*/}
            {/*    <Container header={<h2>Penalties & Interest Information</h2>}>*/}
            {/*        <ColumnLayout columns={3} variant="text-grid">*/}
            {/*            <SpaceBetween size="l">*/}
            {/*                <ValueWithLabel label="INSS Penalties">--*/}
            {/*                </ValueWithLabel>*/}
            {/*                <ValueWithLabel label="ISS Penalties">--*/}
            {/*                </ValueWithLabel>*/}
            {/*            </SpaceBetween>*/}
            {/*            <SpaceBetween size="l">*/}
            {/*                <ValueWithLabel label="IRRF Penalties">--</ValueWithLabel>*/}
            {/*            </SpaceBetween>*/}
            {/*        </ColumnLayout>*/}
            {/*    </Container>*/}
            {/*    <br/>*/}
            {/*<Container header={<h2>Rejection Information</h2>}>*/}
            {/*    <ColumnLayout columns={3} variant="text-grid">*/}
            {/*        <SpaceBetween size="l">*/}
            {/*            <ValueWithLabel label="Reason for rejection">--*/}
            {/*            </ValueWithLabel>*/}
            {/*            <ValueWithLabel label="Comments">--*/}
            {/*            </ValueWithLabel>*/}
            {/*        </SpaceBetween>*/}
            {/*        <SpaceBetween size="l">*/}
            {/*            <ValueWithLabel label="Barcode">--</ValueWithLabel>*/}
            {/*        </SpaceBetween>*/}
            {/*    </ColumnLayout>*/}
            {/*</Container>*/}
            {/*<br/>*/}
            {/*<Container header={<h2>Process Information</h2>}>*/}
            {/*    <ColumnLayout columns={3} variant="text-grid">*/}
            {/*        <SpaceBetween size="l">*/}
            {/*            <ValueWithLabel label="REC Number">--*/}
            {/*            </ValueWithLabel>*/}
            {/*            <ValueWithLabel label="Status Open Interface">--*/}
            {/*            </ValueWithLabel>*/}
            {/*        </SpaceBetween>*/}
            {/*        <SpaceBetween size="l">*/}
            {/*            <ValueWithLabel label="Comments">--</ValueWithLabel>*/}
            {/*        </SpaceBetween>*/}
            {/*    </ColumnLayout>*/}
            {/*</Container>*/}
        </div>
    )
}

export default InvoiceInformation;